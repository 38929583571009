import React, { FC, useEffect, useState } from 'react'

import { Icon } from '@juullabs/react-components'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { LocalizedLink } from 'components/common/LocalizedLink'

import styles from './PrimaryNotification.module.scss'

export type Props = {
  allowDangerousHTML?: boolean
  contentClassNames?: string
  dismissible?: boolean
  linkTargetBlank?: boolean
  linkText?: string
  linkUrl?: string
  message: string
  millisecondsUntilFade?: number
  onDismiss?(): void
  onLinkClick?(event: React.MouseEvent<HTMLAnchorElement>): void
  style?: 'auto-ship' | 'cart' | 'warning'
}

export const PrimaryNotification: FC<Props> = ({
  allowDangerousHTML,
  contentClassNames,
  dismissible = false,
  linkText,
  linkUrl,
  message,
  millisecondsUntilFade = 5000,
  onDismiss,
  onLinkClick,
  style,
}) => {
  const [active, setActive] = useState(true)

  const containerClasses = classNames(styles.container, {
    [styles.autoship]: style === 'auto-ship',
    [styles.cart]: style === 'cart',
    [styles.warning]: style === 'warning',
  })

  const contentClasses = classNames(
    styles.content,
    contentClassNames,
    {
      [styles.dismissable]: dismissible,
    },
  )

  const dismiss = () => {
    setActive(false)
  }

  useEffect(() => {
    if (!dismissible && millisecondsUntilFade) {
      const timer = setTimeout(dismiss, millisecondsUntilFade)
      return () => clearTimeout(timer)
    }
  }, [])

  return (
    <CSSTransition
      classNames={{
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      in={active}
      onExited={() => onDismiss?.()}
      timeout={300}
      unmountOnExit
    >
      <div
        className={containerClasses}
        data-testid='primary-notification'
      >
        <div className={contentClasses}>
          {allowDangerousHTML ? (
            <span
              className={classNames(styles.message, styles.markdownContent)}
              dangerouslySetInnerHTML={{ __html: message }}
              data-testid='primary-notification-markdown'
            />
          ) : (
            <span
              className={styles.message}
              data-testid='primary-notification-message'
            >
              {message}
            </span>
          )}

          {Boolean(linkUrl) && Boolean(linkText) && (
            <LocalizedLink
              className={styles.link}
              href={linkUrl}
              onClick={event => {
                onLinkClick?.(event)
              }}
            >
              {linkText}
            </LocalizedLink>
          )}

          {dismissible && (
            <button
              className={styles.dismiss}
              onClick={dismiss}
              data-testid='primary-notification-dismiss'
            >
              <Icon name='close' />
            </button>
          )}
        </div>
      </div>
    </CSSTransition>
  )
}
